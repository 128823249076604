<template>
    <div class="page-content w-100 mb-5">
      <div class="row g-0">
        <div class="col-sm-12">
          <p class="text-center page-heading">Settings</p>
        </div>
      </div>
      <div class="mobie-setting-mainDiv">
        <router-link to="/clinicianothers/about-me">
          <div class="SettingName">About me</div>
        </router-link>
        <router-link to="/clinicianothers/my-practice">
            <div  class="SettingName">My practice</div>
        </router-link>
        <router-link to="/clinicianothers/groups">
            <div  class="SettingName">Groups</div>
        </router-link>
        <router-link to="/clinicianothers/messages">
            <div class="SettingName">Message settings</div>
        </router-link>
      </div>
    </div>
   <clinicianotherfooter></clinicianotherfooter>
  </template>
  <script>
  // import axios from "axios";
  import clinicianotherfooter from "./clinician-others-footer.vue"
  export default {
    components: {
        clinicianotherfooter
      },
    data() {
      return {
      };
    },
    created: function () {},
  
    methods: {
    //   async getAccess(getvalues) {
    //     const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    //     axios
    //       .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
    //       .then((response) => {
    //         if (response?.data) {
    //           if (response.data?.status === null) {
    //             this.$swal(
    //               "Please complete the About Me section of the Settings Page"
    //             );
    //           } else if (response.data?.status?.status === "UnderReview") {
    //             this.$swal(
    //               "Your request for access is under review. Please wait."
    //             );
    //           } else if (response.data?.status?.status === "Approved") {
    //             if (getvalues === "practice") {
    //               window.scrollTo(0, 0);
    //               this.$router.push( "/doctor/practice");
    //             } 
    //             else if (getvalues === "communities-groups") {
    //               window.scrollTo(0, 0);
    //               this.$router.push("/doctor/communities-groups");
    //             }
    //           } else if (response.data?.status?.status === "Rejected") {
    //             this.$swal(
    //               "Please fix the review comments of your request for access"
    //             );
    //           }
    //         }
    //       });
    //   },
    },
  };
  </script>
  <style>
  .SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  
  .mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  </style>
  